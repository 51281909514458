import {Dispatch} from "redux";
import IApiError from "../../types/IApiError";
import service from "../service";
import {IInvestmentProduct} from "../types";

export enum ActionTypes {
  LOAD_PRODUCTS_QUALIFICATION_REQUEST = "[Caps] Load products qualification request",
  LOAD_PRODUCTS_QUALIFICATION_SUCCESS = "[Caps] Load products qualification success",
  LOAD_PRODUCTS_QUALIFICATION_FAIL = "[Caps] Load products qualification fail",
}

interface ILoadProductsQualificationFailAction {
  type: typeof ActionTypes.LOAD_PRODUCTS_QUALIFICATION_FAIL;
  payload: {error: IApiError};
}
interface ILoadProductsQualificationRequestAction {
  type: typeof ActionTypes.LOAD_PRODUCTS_QUALIFICATION_REQUEST;
}
interface ILoadProductsQualificationSuccessAction {
  type: typeof ActionTypes.LOAD_PRODUCTS_QUALIFICATION_SUCCESS;
  payload: {
    products: IInvestmentProduct[];
    productElementaryClasses: IInvestmentProduct[];
  };
}

export const actions = {
  loadProductsQualification:
    (options: {classNeedsCourses?: boolean}) =>
    (dispatch: Dispatch<Actions>) => {
      dispatch(actions.loadProductsQualificationRequest());
      return service
        .loadProductsQualification(options)
        .then(({products, productElementaryClasses}) => {
          dispatch(
            actions.loadProductsQualificationSuccess({
              products,
              productElementaryClasses,
            })
          );
        })
        .catch((error) => {
          dispatch(actions.loadProductsQualificationFail(error));
        });
    },
  loadProductsQualificationFail: (
    error: IApiError
  ): ILoadProductsQualificationFailAction => ({
    payload: {error},
    type: ActionTypes.LOAD_PRODUCTS_QUALIFICATION_FAIL,
  }),
  loadProductsQualificationRequest:
    (): ILoadProductsQualificationRequestAction => ({
      type: ActionTypes.LOAD_PRODUCTS_QUALIFICATION_REQUEST,
    }),
  loadProductsQualificationSuccess: ({
    products,
    productElementaryClasses,
  }: {
    products: IInvestmentProduct[];
    productElementaryClasses: IInvestmentProduct[];
  }): ILoadProductsQualificationSuccessAction => {
    return {
      payload: {products, productElementaryClasses},
      type: ActionTypes.LOAD_PRODUCTS_QUALIFICATION_SUCCESS,
    };
  },
};

export type Actions =
  | ILoadProductsQualificationFailAction
  | ILoadProductsQualificationRequestAction
  | ILoadProductsQualificationSuccessAction;
