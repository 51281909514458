import {IInvestmentCompany, IInvestmentProduct} from "../../Caps/types";
import {ICourse} from "../../Users/types";

function isQualified(product: IInvestmentProduct, coursesHistory: ICourse[]) {
  const neededCourses = product.neededCourses
    ? (JSON.parse(product.neededCourses) as number[][]).reverse()
    : undefined;

  return (
    !neededCourses ||
    neededCourses.some((neededCourses) =>
      neededCourses.every((neededCourse) =>
        coursesHistory.some(
          (course) =>
            course.id === neededCourse.toString() &&
            course.userCourse?.dateQualification
        )
      )
    )
  );
}

export const missingNeededCoursesProducts = (
  coursesHistory: ICourse[],
  needCoursesProducts: {
    products: IInvestmentProduct[];
    productElementaryClasses: IInvestmentProduct[];
  },
  company?: IInvestmentCompany
) => {
  const allNeededCoursesProducts = (needCoursesProducts?.products ?? []).concat(
    needCoursesProducts?.productElementaryClasses ?? []
  );
  const companyNeededCoursesProducts = allNeededCoursesProducts.filter(
    (product) => !company || product.company.id === company?.id
  );
  const missingNeededCoursesProducts = companyNeededCoursesProducts.filter(
    (product) => !isQualified(product, coursesHistory)
  );

  return {
    total: companyNeededCoursesProducts.length,
    missing: missingNeededCoursesProducts.length,
    completed:
      companyNeededCoursesProducts.length - missingNeededCoursesProducts.length,
  };
};
